import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { borderRadius } from '../../../styles/tokens/border';
import { color } from '../../../styles/tokens/color';
import { zIndex } from '../../../styles/tokens/zIndex';
import Variables from '../../../styles/variables';

export const LinkTitle = styled.span`
    font-family: ${Variables.fonts.fontRegular};
    color: ${color.textLightPrimary};
    font-size: ${rem(17)};
    line-height: ${rem(24)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

export const LinkDescription = styled.span`
    font-family: ${Variables.fonts.fontRegular};
    color: ${color.textLightSecondary};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${rem(44)};
    min-width: ${rem(44)};
    max-height: ${rem(44)};
    max-width: ${rem(44)};
    height: ${rem(44)};
    width: ${rem(44)};
    margin-right: ${rem(12)};
    overflow: hidden;
    border-radius: ${borderRadius.radius16};

    img {
        width: inherit;
        height: inherit;
        object-fit: cover;
    }
`;

export const GroupIconWrapper = styled(IconWrapper)`
    max-height: ${rem(24)};
    max-width: ${rem(24)};
    min-height: ${rem(24)};
    min-width: ${rem(24)};
    height: ${rem(24)};
    width: ${rem(24)};
    border-radius: ${borderRadius.radius4};
    flex-shrink: 0;
`;

export const Item = styled.a<{ isLoading: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${rem(10)} 0;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;

    ${({ isLoading }) => isLoading && css`pointer-events: none`};

    &:hover {
        ${LinkTitle} {
            color: ${color.textLightSecondary};
        }

        ${GroupIconWrapper} {
            svg {
                & > path:last-of-type {
                    fill: ${color.controlsLightPrimaryActive};
                }
            }
        }
    }

    &:after {
        content: '';
        cursor: default;
        position: absolute;
        display: none;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.2s linear;
        background-color: ${color.loaderBackground};
        ${({ isLoading }) => isLoading && css`display: block`};
        z-index: ${zIndex.level2};
    }
`;

export const LinkTextBlock = styled.span`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
`;
