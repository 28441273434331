import styled, { css } from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { media, mobile, tablet } from '../../../styles/themes/media';
import Variables from '../../../styles/variables';

export const GroupTitle = styled.div`
    box-sizing: border-box;
    padding: ${rem(20)} 0;
    display: flex;
    border-bottom: ${Variables.border.standard};
    cursor: pointer;
    font-size: ${rem(20)};
    line-height: ${rem(24)};
    text-transform: uppercase;
    font-family: ${Variables.fonts.fontWideMedium};
`;

const mobileStyles = css`
    border: ${Variables.border.standard};
    padding: ${rem(32)} ${rem(20)} ${rem(24)};

    ${GroupTitle} {
        border-bottom: none;
        padding: ${rem(0)};
        height: auto;
    }
`;

export const Wrapper = styled.div`
    border: ${Variables.border.standard};
    border-radius: ${Variables.borderRadiusLg};
    padding: 0 ${rem(20)} ${rem(20)};

    &:nth-of-type(3n) {
        margin-right: 0;
    }

    a {
        text-decoration: none;
    }

    ${media(tablet, mobile)`${mobileStyles}`};
`;

export const GroupList = styled.div`
    display: grid;
    margin-top: ${rem(16)};
    font-family: ${Variables.fonts.fontRegular};
    font-weight: 400;

    > div {
        width: 100%;
    }
`;
