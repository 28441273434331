import styled from 'styled-components';
import { rem } from '../../../helpers/rem-converter';
import { mobile, tablet } from '../../../styles/themes/media';
import { color } from '../../../styles/tokens/color';
import Variables from '../../../styles/variables';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${rem(32)};

    ${tablet`margin-bottom: ${rem(24)}`};
    ${mobile`margin-bottom: ${rem(20)}`};
`;

export const Title = styled.h3`
    font-family: ${Variables.fonts.fontWideMedium};
    font-size: ${rem(20)};
    line-height: ${rem(24)};
    color: ${color.textLightPrimary};
    margin-bottom: ${rem(12)};
`;
