import React, { FC } from 'react';
import * as Styled from './segment-products.styles';
import { ChildSegment } from '../../../models/segment';
import ChildSegmentLink from '../child-segment-link/child-segment-link';

export const SegmentProducts: FC<{ childSegment: ChildSegment }> = ({ childSegment }) => {
    return (
        <Styled.Wrapper>
            {childSegment.name && <Styled.Title>{childSegment.name}</Styled.Title>}
            {childSegment.products?.map(product => <ChildSegmentLink product={product} key={childSegment.id} />)}
        </Styled.Wrapper>
    );
};
