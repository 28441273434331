import styled from 'styled-components';
import { rem } from '../../helpers/rem-converter';
import Variables from '../../styles/variables';

const H1 = styled.h1`
    color: ${Variables.colors.deepBlueColor};
    font-size: ${rem(64)};
    font-family: ${Variables.fonts.fontMedium};
    line-height: 1.06;
`;

export { H1 };
