import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import * as Styled from './child-segment-groups.styles';
import { getProductGroupsAttrName } from './child-segment-groups.utils';
import { selectChildSegmentGroups } from '../../../redux/segments/segments-selectors';
import { ChildSegmentGroup } from '../child-segment-group/child-segment-group';

export const ChildSegmentGroups: FC = () => {
    const childSegmentsGroups = useSelector(selectChildSegmentGroups);

    return childSegmentsGroups?.length ? (
        <Styled.ChildSegmentGroupsList data-test={getProductGroupsAttrName()}>
            {/*splice для того, чтобы групп было не больше трех, как по макетам*/}
            {childSegmentsGroups
                .slice(0)
                .splice(0, 3)
                .map((group, index) => (
                    <ChildSegmentGroup key={group.name} group={group} index={index} />
                ))}
        </Styled.ChildSegmentGroupsList>
    ) : null;
};
