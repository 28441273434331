import React, { useState } from 'react';
import * as Styled from './hero-search.styles';
import { getTitleAttrName } from './hero-search.utils';
import { useAppRouter } from '../../../custom-hooks/use-app-router';
import { useMyMtsApp } from '../../../custom-hooks/use-my-mts-app';
import { itemSchemaSearchAction } from '../../../meta-tags';
import Variables from '../../../styles/variables';
import { SearchInput } from '../search-input/search-input';
import { searchArticlesMaxLength } from '../search-input/search-input.constants';

interface HeroSearchProps {
    title?: string | JSX.Element;
}

export const HeroSearch: React.FC<HeroSearchProps> = ({ title }) => {
    const [isLoading, setIsLoading] = useState(false);
    const router = useAppRouter();
    const { isMyMtsApp } = useMyMtsApp();
    const MainTitle= <>Центр поддержки{Variables.nbWhiteSpace}МТС</>;

    const handleOnSearch = (value: string) => {
        setIsLoading(true);
        router.push(`/search?s=${value}`);
    };

    return (
        <Styled.Wrapper
            itemScope
            itemType={itemSchemaSearchAction}
            isMyMtsApp={isMyMtsApp}
        >
            {!isMyMtsApp && (
                <Styled.Title data-test={getTitleAttrName()} itemProp="agent">
                    <Styled.TitleContentMobile>
                        {MainTitle}
                    </Styled.TitleContentMobile>
                    <Styled.TitleContentDesktop>
                        {MainTitle}<br/>{title}
                    </Styled.TitleContentDesktop>
                </Styled.Title>
            )}
            <Styled.SearchContainer itemProp="object" isMyMtsApp={isMyMtsApp}>
                <SearchInput onSearch={handleOnSearch} maxLength={searchArticlesMaxLength} isLoading={isLoading} />
            </Styled.SearchContainer>
        </Styled.Wrapper>
    );
};
